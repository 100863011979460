<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="cleardata()"
            >清空</el-button
          >
          <el-button type="primary" :disabled="loading" @click="loadArticles(1)"
            >查询</el-button
          >
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <el-table
        :data="articles"
        stripe
        :height="tableHeight"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" label="工单号"> </el-table-column>
        <el-table-column prop="customerName" label="客户姓名">
        </el-table-column>

        <el-table-column prop="solvedFlag" label="是否解决" key="area">
          <template v-slot="scope">
            {{
              solvedFlagoptions[scope.row.solvedFlag] &&
              solvedFlagoptions[scope.row.solvedFlag].label
            }}
          </template>
        </el-table-column>
        <el-table-column prop="area" label="大区" key="area">
          <template v-slot="scope">
            {{
              areaoptions[scope.row.area] && areaoptions[scope.row.area].label
            }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="问题类型" key="type">
          <template v-slot="scope">
            {{
              problemtypeaoptions[scope.row.type] &&
              problemtypeaoptions[scope.row.type].label
            }}
          </template>
        </el-table-column>
        <el-table-column prop="assessedTime" label="评价时间">
        </el-table-column>
        <el-table-column prop="assessment" width="420" label="评价内容"> </el-table-column>
        <el-table-column prop="completedTime" label="完成时间">
        </el-table-column>
        <el-table-column prop="completedByName" label="完成人">
        </el-table-column>
        <el-table-column prop="faeOperatorName" label="FAE操作人">
        </el-table-column>
        <el-table-column prop="keyword" width="420" label="问题关键词"> </el-table-column>
        <el-table-column prop="description" width="420" label="问题描述"> </el-table-column>

        <el-table-column width="60" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="getdetaile(scope.row.workOrderId)"
                  circle
                  icon="el-icon-search"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
      </el-card>
    <el-dialog
      title="工单详情:"
      modal-append-to-body
      :visible.sync="showOrder"
      width="1100px"
    >
      <div class="desbody"> 
        <el-descriptions direction="horizontal" :column="1">
          <el-descriptions-item label="满意度（星级）">
            <el-rate v-model="assessmentVo.satisfaction"></el-rate>
          </el-descriptions-item>
          <el-descriptions-item label="评价内容">{{
            assessmentVo.assessment
          }}</el-descriptions-item>

          <el-descriptions-item label="是否解决">
            {{ solvedFlagoptions[assessmentVo.solvedFlag] && solvedFlagoptions[assessmentVo.solvedFlag].label }}
          </el-descriptions-item  >
        </el-descriptions>
      </div>

      <div class="destitle">{{ $t("jibenxinxi") }}</div>

      <el-descriptions class="desbasebody" :column="3">
        <el-descriptions-item :label="$t('workOrderNo')">{{
          FAEdatile.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('productModelType')">{{
          FAEdatile.productModelType
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('area')">{{
          areaoptions[FAEdatile.area] && areaoptions[FAEdatile.area].label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('type')">{{
          problemtypeaoptions[FAEdatile.type] &&
          problemtypeaoptions[FAEdatile.type].label
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('platform')">{{
          pingtaioptions[FAEdatile.platform] &&
          pingtaioptions[FAEdatile.platform].label
        }}</el-descriptions-item>

        <el-descriptions-item :label="$t('urgentType')">
          {{ urgentStatustext[FAEdatile.urgentType] }}
        </el-descriptions-item>

        <el-descriptions-item :label="$t('faeOperatorName')">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('personLiable')">{{
          FAEdatile.personLiable
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('createdName')">{{
          FAEdatile.createdName
        }}</el-descriptions-item>

        <el-descriptions-item :label="$t('complaintFlag')">{{
          complaintoptions[FAEdatile.complaintFlag] &&
          complaintoptions[FAEdatile.complaintFlag].label
        }}</el-descriptions-item>
      </el-descriptions>

      <div class="destitle">{{ $t("faexq") }}</div>

      <div class="desbody">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('keyword')">{{
            FAEdatile.keyword
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('stuckPoint')">{{
            difoptions[FAEdatile.stuckPoint] &&
            difoptions[FAEdatile.stuckPoint].label
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('createdTime')">{{
            FAEdatile.createdTime
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item :label="$t('wentichulifangan')" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in solutionlinedata"
                :key="index"
                :timestamp="activity.time"
              >
                <p style="white-space: pre-line">
                  {{ activity.detail }}
                </p>
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('description')" :span="3">
            <p style="white-space: pre-line">
              {{ FAEdatile.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item :label="$t('customerDescription')" :span="3">{{
            FAEdatile.customerDescription
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('tupian')" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in FAEdatile.image"
              :key="url"
              :src="url"
              :previewSrcList="FAEpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item :label="$t('fujian')" :span="3">
            <p v-for="{ url, path } in FAEdatile.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>

      <div class="destitle" v-if="this.yanfadatile.orderNo != null">
        {{ $t("yanfaxiangqing") }}
      </div>

      <div class="desbody" v-if="this.yanfadatile.orderNo != null">
        <el-descriptions :column="3">
          <el-descriptions-item :label="$t('createdTime')">{{
            yanfadatile.createdTime
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('zhuangtai')">{{
            attributeoptions[yanfadatile.attribute] &&
            attributeoptions[yanfadatile.attribute].label
          }}</el-descriptions-item>
          <el-descriptions-item :label="$t('personLiable')">{{
            yanfadatile.personLiable
          }}</el-descriptions-item>
        </el-descriptions>
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item :label="$t('jieshishuoming')" :span="3">
            <el-timeline>
              <el-timeline-item
                v-for="(activity, index) in remakelinedate"
                :key="index"
                :timestamp="activity.time"
              >
                {{ activity.detail }}
              </el-timeline-item>
            </el-timeline>
          </el-descriptions-item>

          <el-descriptions-item :label="$t('tapdlianjie')" :span="3">{{
            yanfadatile.tapdLink
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('yfkd')" :span="3">{{
            yanfadatile.developStuckPoint
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('jjfangan')" :span="3">{{
            yanfadatile.solution
          }}</el-descriptions-item>

          <el-descriptions-item :label="$t('tupian')" :span="3">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              fit="fit"
              v-for="{ url } in yanfadatile.imageInfos"
              :key="url"
              :src="url"
              :previewSrcList="YFpreviewList"
            />
          </el-descriptions-item>

          <el-descriptions-item :label="$t('fujian')" :span="3">
            <p v-for="{ url, path } in yanfadatile.fileInfos" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getAssessListUrl, getOneAssessUrl } from "@/api/fae";
import {
  productModelTypeOptions,
  areaoptions,
  problemtypeaoptions,
  prodectoptions,
  netoptions,
  pingtaioptions,
  difoptions,
  attributeoptions,
  complaintoptions,
  solvedFlagoptions,
} from "@/options/index";
import { suanfa } from "@/utils/index";

export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      productModelTypeOptions,
      areaoptions,
      problemtypeaoptions,
      prodectoptions,
      netoptions,
      pingtaioptions,
      difoptions,
      attributeoptions,
      complaintoptions,
      solvedFlagoptions,

      editform: {
        workOrderId: null,
        deviceModel: null,
        imei: null,
        iccid: null,
        simCardOperator: null,
        description: null,
        remark: null,
      },
      reopenform: {},
      innerVisible: false,
      showAssignFaeOperator: false,
      showChangeES: false,
      FAElist: [],
      ECSaleslist: [],
      showSolveDio: false,
      showReopen: false,
      previewList: [],
      ESpreviewList: [],
      tableHeight: window.innerHeight - 320,
      loading: true, // 表单数据加载中 loading
      articles: [], // 文章数据列表
      totalCount: 0, // 总数据条数

      form: {
        creatTimeBefore: null,
        createTimeAfter: null,
        size: 20, // 每页大小
        page: 1, // 当前页码
      },
      setFaeManForm: {
        workOrderId: null,
        faeOperatorName: null,
      },
      changefollowForm: {
        workOrderId: null,
        followPersonName: null,
      },
      EsOrderSolveform: {
        workOrderId: null,
        solution: null,
        remark: null,
      },
      esservedatiledata: {},
      FAEdatile: {},
      yanfadatile: {},
      assessmentVo: {},
      showOrder: false,

      solutionlinedata: [],
      remakelinedate: [],

      urgentStatustext: [
        this.$t("di"), // 1
        this.$t("zhong"), // 1
        this.$t("gao"), // 1
        this.$t("jigao"), // 1
      ],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.form.workOrderNo = this.$route.query.orderNo;
    window.addEventListener("resize", this.getTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.loadArticles(1);
  },
  methods: {
    async getdetaile(id) {
      this.FAEdatile = {};
      this.yanfadatile = {};
      try {
        const res = await getOneAssessUrl({
          workOrderId: id,
        });
        if (res.data.code === 200) {
          this.assessmentVo = res.data.data?.assessmentVo || {};

          this.FAEdatile = {
            ...res.data.data.technicalSupportWorkOrderVo,
            attachment: (
              res.data.data.technicalSupportWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.technicalSupportWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };

          if (res.data.data.developmentWorkOrderVo) {
            this.yanfadatile = {
              ...res.data.data.developmentWorkOrderVo,
              // attachment: (res.data.data.fileInfos || []).map(
              //   ({ path, url }) => ({
              //     path,
              //     url,
              //   })
              // ),
              // image: (res.data.data.imageInfos || []).map(({ path, url }) => ({
              //   path,
              //   url,
              // })),
            };
          }

          this.solutionlinedata = suanfa(this.FAEdatile.solution, "#solve#");
          this.remakelinedate = suanfa(this.yanfadatile.remark);

          this.showOrder = true;
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1) {
      this.loading = true;
      try {
        const res = await getAssessListUrl(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}

.article-container {
  height: 100%;
}

.list-table {
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.filter-card {
  margin-bottom: 5px;
}
.flexparents {
  margin-bottom: 5px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}

.zdhh {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.floter {
  display: flex;
  justify-content: space-between;
}
/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  margin-top: 10px;
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}
.desbody {
  margin-top: 10px;
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}
</style>
